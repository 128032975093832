'use client';

import type { StaticImageData } from 'next/image';
import type { ReactNode } from 'react';
import React from 'react';
import { cx } from 'class-variance-authority';
import Image from 'next/image';

import { Button } from '@zealy/design-system';
import { useDidMount } from '@zealy/design-system/src/hooks/useDidMount';
import { ArrowRightLine } from '@zealy/icons';
import { JSONSafeParse } from '@zealy/utils';

import { COMMUNITY_URL } from '#constants';
import { useCommunitiesFlags } from '#context/FeatureFlags';

import blue from './assets/blue.webp';
import pink from './assets/pink.webp';
import plant from './assets/plant.webp';

export const FeatureCard = ({
  title,
  description,
  image = blue,
  href,
  className,
  showIllustration = false,
  rightImage,
}: {
  title: ReactNode;
  description: string;
  image?: string | StaticImageData;
  href: string;
  className?: string;
  showIllustration?: boolean;
  rightImage?: string;
}) => (
  <a
    href={href}
    className={cx(
      'rounded-component-md h-[244px] w-full px-300 sm:px-500 py-400 sm:py-500 relative z-10 hover:blur-child transition-transform hover:scale-[1.005] duration-200 ease-out',
      className,
    )}
  >
    <div className="max-w-[330px]">
      <h4 className="heading-3 text-primary w-full">{title}</h4>
      <p className="body-lg mt-200 mb-100 w-full text-white/90 line-clamp-2">{description}</p>
      <Button variant="ghost" rightIcon={<ArrowRightLine />} className="-translate-x-200">
        Learn more
      </Button>
    </div>

    {showIllustration && (
      <Image
        src={plant}
        alt=""
        width={200}
        height={94}
        loading="lazy"
        decoding="async"
        className="select-none pointer-events-none absolute top-0 right-200 saturate-150 hidden sm:block md:hidden lg:block"
      />
    )}

    {rightImage && (
      <Image
        src={rightImage}
        alt={`${title} right image`}
        width={64}
        height={64}
        className="absolute right-400 lg:top-500 bottom-200 flex items-center justify-center rounded-3xl aspect-square max-h-[88px] max-w-[88px] min-h-[64px] min-w-[64px] overflow-clip"
      />
    )}

    <Image
      src={image}
      alt=""
      width={204}
      height={100}
      className="select-none pointer-events-none absolute top-0 bottom-0 left-0 right-0 bg-cover w-full h-full rounded-component-md blurred transition-all duration-300 ease-in-out z-[-1]"
    />

    <Image
      src={image}
      alt=""
      width={204}
      height={100}
      className="select-none pointer-events-none absolute top-0 bottom-0 left-0 right-0 bg-cover w-full h-full rounded-component-md z-[-1] saturate-150"
    />
  </a>
);

interface LandingBanner {
  title: string;
  image: string;
  url: string;
  showIllustration: boolean;
  description: string;
  rightImage?: string;
}

export const Featured = () => {
  const { landing_top_banners } = useCommunitiesFlags(['landing_top_banners']);
  const landingBanners = JSONSafeParse(
    (landing_top_banners?.value as string) || '[]',
  ) as LandingBanner[];

  const didMount = useDidMount();

  if (!didMount) return null;

  return (
    <div className="w-full flex gap-300 pt-800 pb-600 mx-auto px-100">
      {landingBanners.slice(0, 2).map((banner, index) => {
        const titleSplit = banner.title.split('<br />');
        return (
          <FeatureCard
            key={`landing-top-banner-${index}`}
            title={
              <>
                {titleSplit.map((line, titleSplitIndex) => (
                  <React.Fragment key={titleSplitIndex}>
                    {titleSplitIndex < titleSplit.length - 1 ? (
                      <>
                        {line}
                        <br />
                      </>
                    ) : (
                      line
                    )}
                  </React.Fragment>
                ))}
              </>
            }
            description={banner.description}
            image={banner.image}
            href={banner.url}
            showIllustration={banner.showIllustration}
            className={index === 1 ? 'hidden md:block' : undefined}
            rightImage={banner.rightImage}
          />
        );
      })}

      {landingBanners.length === 0 && (
        <FeatureCard
          title={
            <>
              Exciting news! <br />
              Zealy’s community is launching.
            </>
          }
          description="Join us to be part of the movement."
          image={blue}
          href={COMMUNITY_URL}
          showIllustration
        />
      )}

      {landingBanners.length < 2 && (
        <FeatureCard
          title={
            <>
              New name, <br />
              same awesome product
            </>
          }
          description="Crew3 is now Zealy. We’re excited to share our new name and brand with you."
          image={pink}
          href="https://www.notion.so/crew3/Crew3-is-now-Zealy-d1b2193e3a954b85a0845d4a41472b40"
          className="hidden md:block"
        />
      )}
    </div>
  );
};
